import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import ApplyToJob from "../components/forms/ApplyToJob";

export default function SingleJob() {
  const { id } = useParams();
  const [job, setJob] = useState({
    id: "",
    name: "",
    description: "",
    requirements: "",
  });
  const { t } = useTranslation();

  const getJob = () => {
    axios
      .get(`/jobs/${id}`)
      .then((res) => {
        setJob(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getJob();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="responsive-padding">
        <h1 className="flex items-center gap-1 font-medium py-4 mt-4">
          <Breadcrumb />
        </h1>
        {job && (
          <div>
            <h1 className="text-3xl font-semibold py-6">{job?.name}</h1>
            <h3 className="text-xl my-4 font-medium">{t("job-describtion")}</h3>
            <p
              className="leading-8 w-[80%] px-10"
              dangerouslySetInnerHTML={{ __html: job?.description }}
            />
            <h3 className="text-xl my-4 font-medium">
              {t("job-requirements")}
            </h3>
            <div
              className="list-disc w-[80%] px-10 flex flex-col gap-2"
              dangerouslySetInnerHTML={{ __html: job?.requirements }}
            />
          </div>
        )}
      </div>
      <ApplyToJob />
    </div>
  );
}
