import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../components/Breadcrumb";
import CareerBox from "../components/careers/CareerBox";

export default function Careers() {
  const [jobs, setJobs] = useState([]);
  const { t } = useTranslation();

  const getJobs = () => {
    axios
      .get("/jobs")
      .then((res) => {
        setJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div className="container mx-auto responsive-padding">
      <h1 className="flex items-center gap-1 font-medium py-4 mt-4">
        <Breadcrumb />
      </h1>
      <h1 className="text-3xl font-semibold py-6">{t("jobs")}</h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
        {jobs &&
          jobs?.map((el: any) => {
            return (
              <CareerBox
                id={el?.id}
                name={el?.name}
                description={el?.description}
              />
            );
          })}
      </div>
    </div>
  );
}
