import { useTranslation } from "react-i18next";
import service1 from "../../icons/service1.svg";
import service2 from "../../icons/service2.svg";
import service3 from "../../icons/service3.svg";
import { Breadcrumb } from "../Breadcrumb";

const OurServices = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto responsive-padding">
      <h1 className="flex items-center gap-1 font-medium py-4 mt-4">
        <Breadcrumb />
      </h1>

      <div className="flex justify-between items-center py-4">
        <h1 className="font-semibold text-2xl">{t("ourServices")}</h1>
        <div></div>
      </div>

      <div className="flex flex-col justify-between gap-16 pt-8">
        <div className="flex flex-col gap-4 px-6 pb-4 rounded-lg bg-SecondaryGray">
          <div className="flex justify-between items-end">
            <div className="flex items-end gap-2">
              <img src={service1} className="mt-[-20px]" />
              <h3 className="font-semibold text-lg">{t("malls")}</h3>
            </div>
            {/* <p className="px-4 font-semibold text-PrimaryOrange cursor-pointer">{t("show")}</p> */}
          </div>
          <div className="text-sm text-PrimaryGray">{t("mallService")}</div>
        </div>

        <div className="flex flex-col gap-4 px-6 pb-4 rounded-lg bg-SecondaryGray">
          <div className="flex items-end gap-2">
            <img src={service2} className="mt-[-20px]" />
            <h3 className="font-semibold text-lg">{t("corp")}</h3>
          </div>
          <div className="text-sm text-PrimaryGray">{t("corpService")}</div>
        </div>

        <div className="flex flex-col gap-4 px-6 pb-4 rounded-lg bg-SecondaryGray">
          <div className="flex items-end gap-2">
            <img src={service3} className="mt-[-20px]" />
            <h3 className="font-semibold text-lg">{t("tourist")}</h3>
          </div>
          <div className="text-sm text-PrimaryGray">{t("touristService")}</div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
