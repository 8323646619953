import { useTranslation } from "react-i18next";
import aave from "../icons/aave-gray.svg";
import googlePlay from "../icons/google-play-gray.svg";
import location from "../icons/location-minus.svg";
import RayatLogo from "../icons/logo.png";
import phone from "../icons/phone.svg";
import sms from "../icons/sms-gray.svg";
import Twitter from "../icons/twitter-gray.svg";
import whatsapp from "../icons/whatsapp-gray.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-50 mt-0 md:mt-[200px]">
      <div className="py-10 container mx-auto responsive-padding">
        <div className="grid grid-cols-12 gap-4 text-center md:ltr:text-left md:rtl:text-right py-6 mb-6 border-b border-gray-300">
          <div className="flex justify-center md:justify-start col-span-12 md:col-span-6 lg:col-span-4">
            <img src={RayatLogo} alt="Rayat solution" className="h-14" />
          </div>

          <div className="grid grid-cols-12 col-span-12 md:col-span-6 lg:col-span-4">
            <div className="flex flex-col gap-2 col-span-6">
              <h3 className=" text-lg font-semibold pb-1">{t("pages")}</h3>
              <div className="flex flex-col gap-2 text-PrimaryGray">
                <a href="/" className="hover:underline">
                  {t("home")}
                </a>

                <a href="/services" className="hover:underline">
                  {t("services")}
                </a>

                <a href="/careers" className="hover:underline">
                  {t("careers")}
                </a>
              </div>
            </div>

            <div className="flex flex-col gap-2 pb-1 col-span-6">
              <h3 className=" text-lg font-semibold">{t("knowus")}</h3>
              <div className="flex flex-col gap-2 text-PrimaryGray">
                <a href="/about" className="hover:underline">
                  {t("about")}
                </a>
                {/* <a href="/faqs" className="hover:underline">
                  {t("faqs")}
                </a> */}
                <a href="/contact" className="hover:underline">
                  {t("contactus")}
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center col-span-12 lg:col-span-4 md:col-span-6">
            <div className="flex flex-col gap-2">
              <h3 className=" text-lg font-semibold">{t("contactInfo")}</h3>
              <div className="flex items-center gap-2">
                <img src={sms} alt="E-mail" className="w-6" />
                <div className="bg-gray-400 w-[1px] h-5"></div>
                <a
                  href={`mailhref:${t("email")}`}
                  className="text-gray-400 text-sm font-poppins"
                >
                  {t("email")}
                </a>
              </div>

              <div className="flex items-center gap-2">
                <img src={phone} alt="E-mail" className="w-6" />
                <div className="bg-gray-400 w-[1px] h-5"></div>
                <a
                  href={`tel:${t("phone")}`}
                  className="text-gray-400 text-sm font-poppins"
                >
                  {t("phone")}
                </a>
              </div>

              <div className="flex items-center gap-2">
                <img src={location} alt="E-mail" className="w-6" />
                <div className="bg-gray-400 w-[1px] h-5"></div>
                <p className="text-gray-400 text-sm">{t("location")}</p>
              </div>
            </div>
            <div>
              <h3 className=" text-lg font-semibold py-4">{t("follow")}</h3>
              <div className="flex items-center gap-3">
                {/* <a href="http://" target="_blank" rel="noreferrer">
                  <img src={snapchat} alt="Snap Chat" />
                </a> */}
                <a
                  href="https://twitter.com/rayatsolutions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" width="18" height="18" />
                </a>
                <a
                  href="https://wa.me/966564914949"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={whatsapp}
                    alt="whatsapp"
                    style={{ width: "25px" }}
                  />
                </a>
                <div className="bg-gray-400 w-[1px] h-5"></div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.impulsescorp.rayat_solutions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlay} alt="Google Play" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/rayat-solutions/id6446064909"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={aave} alt="Aave" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center flex-wrap gap-3">
          <div className="font-medium">
            {t("copyRight")}
            <span className="text-PrimaryOrange">{t("rayat")}</span>
            <span className="font-poppins">{t("2024")}</span>
          </div>
          {/* <div className="flex gap-2 font-medium">
            <div>{t("chooseLang")}</div>
            <div>
              <ChangeLanguage pos="footer" />
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
