import { useTranslation } from "react-i18next";
import background from "../../icons/partners-bg.svg";
import clients from "../../icons/clients.svg";
import employees from "../../icons/employees.svg";
import building from "../../icons/building.svg";
import events from "../../icons/events.svg";
import logos from "../../icons/index";
import { useRef, useState } from "react";
import PartenerTicker from "../PartenerTicker";
import PartenerTickerRev from "../PartenerTickerRev";

const Partners = () => {
  const { t } = useTranslation();

  return (
    <div className="py-12 md:pt-[200px] md:pb-[100px]">
      <div className="container mx-auto responsive-padding">
        <h1 className="font-semibold text-2xl pb-6">{t("ourPartners")}</h1>
      </div>
      <div className="bg-PrimaryBlue">
        <div className="relative container mx-auto">
          <img
            src={background}
            alt="background"
            className="absolute h-[100%]"
          />
          <div className="grid lg:grid-cols-4 grid-cols-2 gap-5 py-16">
            <div className="flex flex-col justify-center items-center gap-2 text-white">
              <img src={clients} alt="clients" />
              <p className="font-bold text-2xl flex items-center rtl:flex-row-reverse font-poppins">
                <span>+</span>
                <span>150</span>
              </p>
              <p className=" font-bold">{t("clients")}</p>
            </div>

            <div className="flex flex-col justify-center items-center gap-2 text-white">
              <img src={employees} alt="employees" />
              <p className="font-bold text-2xl font-poppins">400</p>
              <p className=" font-bold">{t("employees")}</p>
            </div>

            <div className="flex flex-col justify-center items-center gap-2 text-white">
              <img src={building} alt="clients" />
              <p className="font-bold text-2xl flex items-center rtl:flex-row-reverse font-poppins">
                <span>+</span>
                <span>1500</span>
              </p>
              <p className=" font-bold">{t("building")}</p>
            </div>

            <div className="flex flex-col justify-center items-center gap-2 text-white">
              <img src={events} alt="events" />
              <p className="font-bold text-2xl flex items-center rtl:flex-row-reverse font-poppins">
                <span>+</span>
                <span>100</span>
              </p>
              <p className="font-bold">{t("events")}</p>
            </div>
          </div>
        </div>
      </div>
      {/* hidden parteners */}

      {/* <div className="w-full pt-8">
        <div className="flex flex-col gap-10 overflow-hidden">
          <PartenerTicker
            partnersLogos={[
              `/partners/1.svg`,
              `/partners/2.svg`,
              `/partners/3.svg`,
              `/partners/4.svg`,
              `/partners/5.svg`,
              `/partners/6.svg`,
              `/partners/7.svg`,
              `/partners/8.svg`,
            ]}
          />
          <PartenerTickerRev
            partnersLogos={[
              `/partners/9.svg`,
              `/partners/10.svg`,
              `/partners/11.svg`,
              `/partners/12.svg`,
              `/partners/13.svg`,
              `/partners/14.svg`,
              `/partners/15.svg`,
              `/partners/16.svg`,
            ]}
          />
          <PartenerTicker
            partnersLogos={[
              `/partners/17.svg`,
              `/partners/18.svg`,
              `/partners/19.svg`,
              `/partners/20.svg`,
              `/partners/21.svg`,
              `/partners/22.svg`,
              `/partners/23.svg`,
              `/partners/24.svg`,
            ]}
          />
          <PartenerTickerRev
            partnersLogos={[
              `/partners/37.svg`,
              `/partners/26.svg`,
              `/partners/27.svg`,
              `/partners/28.svg`,
              `/partners/29.svg`,
              `/partners/30.svg`,
              `/partners/31.svg`,
              `/partners/32.svg`,
            ]}
          />
          <PartenerTicker
            partnersLogos={[
              `/partners/33.svg`,
              `/partners/34.svg`,
              `/partners/35.svg`,
              `/partners/36.svg`,
              `/partners/38.svg`,
              `/partners/39.svg`,
              `/partners/40.svg`,
            ]}
          />
          {/*<PartenerTickerRev
            partnersLogos={[
              `/partners/41.svg`,
              `/partners/42.svg`,
              `/partners/43.svg`,
              `/partners/44.svg`,
              `/partners/45.svg`,
              `/partners/46.jpeg`,
              `/partners/47.jpeg`,
              `/partners/48.jpeg`,
            ]}
          />
          <PartenerTicker
            partnersLogos={[
              `/partners/49.jpeg`,
              `/partners/50.jpeg`,
              `/partners/51.jpeg`,
              `/partners/52.jpeg`,
              `/partners/53.jpeg`,
              `/partners/54.jpeg`,
              `/partners/55.jpeg`,
              `/partners/56.jpeg`,
            ]}
          />
          <PartenerTickerRev
            partnersLogos={[
              `/partners/57.jpeg`,
              `/partners/58.jpeg`,
              `/partners/59.jpeg`,
              `/partners/60.jpeg`,
              `/partners/61.jpeg`,
              `/partners/62.jpeg`,
              `/partners/57.jpeg`,
              `/partners/58.jpeg`,
            ]}
          /> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default Partners;
