import Slider from "../components/home/Slider";
import About from "../components/home/About";
import Services from "../components/home/Services";
import Partners from "../components/home/Partners";
import Feedback from "../components/home/Feedback";
import Help from "../components/home/Help";

export default function Home() {
  return (
    <div>
      <Slider />
      <About />
      <Services />
      <Partners />
      {/* <Feedback /> */}
      <Help />
    </div>
  );
}
