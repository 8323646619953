import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import arrowLeft from "../../icons/arrow-left.svg";
import arrowRight from "../../icons/arrow-right.svg";
import circels from "../../icons/circels.png";
import person from "../../icons/slider-guard.png";

const Slider = () => {
  const { t } = useTranslation();

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <div className="bg-PrimaryBlue flex justify-between items-center rtl:flex-row-reverse">
        {/* <div className="bg-SecondaryBlue md:p-2">
          <img src={arrowLeft} alt="left" />
        </div> */}
        <div className="p-2"></div>

        <div className="grid grid-rows-2 gap-4 md:grid-rows-none md:grid-cols-12 w-full container mx-auto">
          <div className="col-span-12 order-last text-center md:ltr:text-left md:rtl:text-right md:order-none md:col-start-2 md:col-span-5 text-white py-16">
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
              }}
              className="text-6xl rtl:text-6xl ltr:font-medium leading-[70px]"
            >
              <h1 className="ltr:tracking-[32px] mb-4">{t("rayatCap")}</h1>
              <h1 className="tracking-[2px]">{t("solutionCap")}</h1>
            </motion.div>

            <motion.div
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
              }}
              className="flex flex-col gap-2 py-16"
            >
              <p>{t("description1")}</p>
              <p>{t("description2")}</p>
              <p>{t("description3")}</p>
            </motion.div>

            <motion.div
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
              }}
            >
              <Link
                to="/services"
                className="bg-white text-PrimaryOrange px-8 py-3 mb-12 rounded-lg font-semibold"
              >
                {t("exploreBtn")}
              </Link>
            </motion.div>
          </div>

          <motion.div
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
            }}
            className="col-span-12 md:col-span-6 relative"
          >
            <img
              src={circels}
              alt="circels"
              className="absolute h-[90%] bottom-0 right-[50%] translate-x-[50%]"
            />
            <img
              src={person}
              alt="person"
              className="absolute h-[90%] bottom-0 right-[45%] translate-x-[50%]"
            />
          </motion.div>
        </div>

        {/* <div className="bg-SecondaryBlue md:p-2">
          <img src={arrowRight} alt="right" />
        </div> */}
        <div className="p-2"></div>
      </div>
    </motion.article>
  );
};

export default Slider;
