import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../components/Breadcrumb";
import ContactForm from "../components/forms/ContactForm";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto">
      <h1 className="flex items-center gap-1 font-medium py-4 mt-4 responsive-padding">
        <Breadcrumb />
      </h1>
      <ContactForm />
    </div>
  );
}
