import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";

const ChangeLanguage = ({ pos }: any) => {
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const localLang = localStorage.getItem("lang");

  const changeLang = (event: any) => {
    let lang = event.target.value;
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    if (lang === "ar") {
      document.body.dir = "rtl";
      document.body.lang = "ar";
    } else {
      document.body.dir = "ltr";
      document.body.lang = "en";
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang") as string;
    i18n.changeLanguage(lang);
    if (lang === "en") {
      document.body.dir = "ltr";
      document.body.lang = "en";
    } else {
      document.body.dir = "rtl";
      document.body.lang = "ar";
    }
  }, []);

  return (
    <>
      <div>
        {pos === "nav" &&
          (localLang === "en" ? (
            <button value={"ar"} onClick={(e) => changeLang(e)}>
              {t("lang")}
            </button>
          ) : (
            <button value={"en"} onClick={(e) => changeLang(e)}>
              {t("lang")}
            </button>
          ))}
      </div>
      <div>
        {pos === "footer" && (
          // localLang === "en" ? (
          //   <button className="text-PrimaryOrange" value={"ar"} onClick={(e) => changeLang(e)}>
          //     {t("selectLang")}
          //   </button>
          // ) : (
          //   <button className="text-PrimaryOrange" value={"en"} onClick={(e) => changeLang(e)}>
          //     {t("selectLang")}
          //   </button>
          // )
          <select
            className="text-PrimaryOrange outline-none bg-gray-50"
            onChange={(e) => changeLang(e)}
          >
            <option value="en" className="font-poppins">
              English
            </option>
            <option value="ar">العربية</option>
          </select>
        )}
      </div>
    </>
  );
};

export default ChangeLanguage;
