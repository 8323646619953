import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import arrow from "../../icons/arrow-circle-right.svg";
import service1 from "../../icons/service1.svg";
import service2 from "../../icons/service2.svg";
import service3 from "../../icons/service3.svg";

const Services = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container mx-auto responsive-padding">
      <div className="flex justify-between items-center py-6">
        <h1 className="font-semibold text-2xl">{t("ourServices")}</h1>
        <Link className="flex text-PrimaryOrange" to="/services">
          <p>{t("exploreMore")}</p>
          <img
            src={arrow}
            alt=""
            className={i18n.language === "ar" ? "rotate-180" : ""}
          />
        </Link>
      </div>

      <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 px-4 pt-6">
        <div className="flex flex-col gap-4 px-6 pb-4 rounded-lg bg-SecondaryGray">
          <div className="flex items-end gap-2">
            <img src={service1} className="mt-[-26px]" alt="" />
            <h3 className="font-semibold text-lg">{t("malls")}</h3>
          </div>
          <div className="text-sm text-PrimaryGray">
            {t("mallsDescription")}
          </div>
        </div>

        <div className="flex flex-col gap-4 px-6 pb-4 rounded-lg bg-SecondaryGray">
          <div className="flex items-end gap-2">
            <img src={service2} className="mt-[-26px]" alt="" />
            <h3 className="font-semibold text-lg">{t("corp")}</h3>
          </div>
          <div className="text-sm text-PrimaryGray">{t("corpDescription")}</div>
        </div>

        <div className="flex flex-col gap-4 px-6 pb-4 rounded-lg bg-SecondaryGray">
          <div className="flex items-end gap-2">
            <img src={service3} className="mt-[-26px]" alt="" />
            <h3 className="font-semibold text-lg">{t("tourist")}</h3>
          </div>
          <div className="text-sm text-PrimaryGray">
            {t("touristDescription")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
