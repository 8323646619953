import { useRoutes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import About from "../pages/About";
import Careers from "../pages/Careers";
import Contact from "../pages/Contact";
import Faqs from "../pages/Faqs";
import Home from "../pages/Home";
import ServicesApp from "../pages/ServiceApp";
import Services from "../pages/Services";
import SingleJob from "../pages/SingleJob";

export default function Routes() {
  const element = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/careers",
          element: <Careers />,
        },
        {
          path: "/careers/:id",
          element: <SingleJob />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/faqs",
          element: <Faqs />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "*",
          element: <Home />,
        },
      ],
    },
    {
      path: "/services/app/:lang",
      element: <ServicesApp />,
    },
  ]);

  return element;
}
