import { t } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface ItemsListInterface {
  name: string;
  link: string;
  icon?: string;
}

export interface BreadcrumbProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ className }) => {
  const [links, setLinks] = React.useState<any[]>([
    { name: t("home"), link: "/" },
  ]);
  const lastElement: ItemsListInterface | null =
    links.length > 1 ? links[links.length - 1] : null;
  const location = useLocation();
  const { i18n } = useTranslation("home");
  const path = location.pathname;

  useEffect(() => {
    const linksArr = path.split("/").filter((item) => item !== "");
    const linksTemp = [{ name: t("home"), link: "/" }];
    linksArr.map((item, index) => {
      if (item === "services") {
        linksTemp.push({ name: t("services"), link: `/${linksArr[index]}` });
      } else if (item === "careers") {
        linksTemp.push({ name: t("careers"), link: `/${linksArr[index]}` });
      } else if (item === "about") {
        linksTemp.push({ name: t("about"), link: `/${linksArr[index]}` });
      } else if (item === "faqs") {
        linksTemp.push({ name: t("faqs"), link: `/${linksArr[index]}` });
      } else if (item === "contact") {
        linksTemp.push({ name: t("contact"), link: `/${linksArr[index]}` });
      } else if (item !== "") {
        linksTemp.push({
          name: t("single-job"),
          link: `/careers/${linksArr[index]}`,
        });
      }
    });
    setLinks(linksTemp);
  }, [path, i18n.language]);

  return (
    <nav className="flex w-[100%] container mx-auto" aria-label="Breadcrumb">
      {links.length > 1 && (
        <ol
          className={`inline-flex items-center space-x-1 md:space-x-3 ${className}`}
        >
          <li className="inline-flex items-center">
            <a
              href={links[0]?.link}
              className="inline-flex items-center font-medium text-gray-700 hover:text-gray-900"
            >
              {links[0]?.name}
            </a>
          </li>
          {links.slice(1, -1).map((item, index) => (
            <li key={index}>
              <div className="flex items-center gap-2">
                <span>/</span>
                <a
                  href={item?.link}
                  className=" font-medium text-gray-700 hover:text-gray-900"
                >
                  {item?.name}
                </a>
              </div>
            </li>
          ))}

          {lastElement && (
            <li aria-current="page">
              <div className="flex items-center gap-2">
                <span>/</span>
                <span className="ml-1 font-medium text-PrimaryOrange md:ml-1">
                  {lastElement?.name}
                </span>
              </div>
            </li>
          )}
        </ol>
      )}
    </nav>
  );
};
