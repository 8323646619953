import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import help1 from "../../icons/help1.svg";
import help2 from "../../icons/help2.svg";

const Help = () => {
  const { t } = useTranslation();

  return (
    <div className="grid h-full md:grid-cols-12 grid-cols-6 items-center gap-10 container mx-auto py-12 md:pt-[200px] responsive-padding">
      <div className="h-full col-span-6 flex justify-between items-center bg-SecondaryGray rounded-lg px-10 py-5 ">
        <div className="flex flex-col justify-between gap-4">
          <div>
            <h1 className="font-semibold text-lg font-poppins mb-2">
              {t("helpTitle1")}
            </h1>
            <p className="text-sm text-PrimaryGray">{t("helpdes1")}</p>
          </div>
          <a
            href="/services"
            className="text-white bg-PrimaryOrange text-sm font-medium px-10 py-3 rounded-lg w-fit"
          >
            {t("helpbtn1")}
          </a>
        </div>
        <div>
          <img src={help1} alt="help" />
        </div>
      </div>

      <div className="h-full col-span-6 flex justify-between items-center bg-SecondaryGray rounded-lg px-10 py-5 ">
        <div className="flex flex-col justify-between gap-4">
          <div>
            <h1 className="font-semibold text-lg font-poppins mb-2">
              {t("helpTitle2")}
            </h1>
            <p className="text-sm text-PrimaryGray">{t("helpdes2")}</p>
          </div>
          <a
            href="/contact"
            className="text-white bg-PrimaryOrange text-sm font-medium px-10 py-3 rounded-lg w-fit"
          >
            {t("helpbtn2")}
          </a>
        </div>
        <div>
          <img src={help2} alt="help" />
        </div>
      </div>
    </div>
  );
};

export default Help;
