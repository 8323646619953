import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../components/Breadcrumb";
import guard1 from "../icons/guard1.png";
import guard2 from "../icons/guard2.png";
import guard3 from "../icons/guard3.png";

export default function About() {
  const [about, setAbout] = useState([]);
  const { t, i18n } = useTranslation();

  const getAbout = () => {
    axios
      .get("/about")
      .then((res) => {
        setAbout(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <div className="container mx-auto responsive-padding">
      <h1 className="flex items-center gap-1 font-medium py-4 mt-4 mb-10">
        <Breadcrumb />
      </h1>

      <div className="flex flex-col gap-20">
        {about &&
          about?.map((el: any) => {
            return (
              <p
                className="text-PrimaryGray text-sm leading-7 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "en"
                      ? el?.descriptionEn
                      : el?.descriptionAr,
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
