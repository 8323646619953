import React from "react";

const useDropdown = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [dorpdowm, setDorpdowm] = React.useState(false);

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dorpdowm && ref.current && !ref.current?.contains(e.target)) {
        setDorpdowm(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dorpdowm]);

  return { ref, dorpdowm, setDorpdowm };
};

export default useDropdown;
