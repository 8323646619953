import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

export interface careerProps {
  id: number;
  name: string;
  description: string;
}

export default function CareerBox({ id, name, description }: careerProps) {
  return (
    <div className="border w-full rounded-lg">
      <h3 className="px-3 py-4 text-xl font-medium">{name}</h3>
      <div
        className="px-3 pb-8 mb-4 w-[80%] h-12 whitespace-wrap overflow-hidden text-ellipsis"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="px-3 py-4 border-t flex justify-end">
        <Link
          to={`/careers/${id}`}
          className="flex items-center gap-1 text-PrimaryOrange "
        >
          {t("jobBtn")}
          <svg
            className="rtl:rotate-180"
            width="20"
            height="9"
            viewBox="0 0 20 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2798 8.93753C15.0898 8.93753 14.8997 8.8675 14.7497 8.7175C14.4597 8.4275 14.4597 7.9475 14.7497 7.6575L17.9397 4.4675L14.7497 1.2775C14.4597 0.987498 14.4597 0.5075 14.7497 0.2175C15.0397 -0.0725 15.5197 -0.0725 15.8097 0.2175L19.5298 3.93753C19.6698 4.07753 19.7497 4.2675 19.7497 4.4675C19.7497 4.6675 19.6698 4.85753 19.5298 4.99753L15.8097 8.7175C15.6597 8.8575 15.4698 8.93753 15.2798 8.93753Z"
              fill="#E9942A"
            />
            <path
              d="M19 5.21777H1C0.59 5.21777 0.25 4.87777 0.25 4.46777C0.25 4.05777 0.59 3.71777 1 3.71777H19C19.41 3.71777 19.75 4.05777 19.75 4.46777C19.75 4.87777 19.41 5.21777 19 5.21777Z"
              fill="#E9942A"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}
