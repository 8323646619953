import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { Input } from "../input";

export default function ContactForm() {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "+966 ",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await sendMessage(values);
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const sendMessage = (data: any) => {
    axios
      .post("/contact", data)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${t("requestSent")}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${t("requestError")}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <div className="max-w-3xl bg-slate-100 rounded-lg mx-auto mt-20 md:py-10 py-5 md:px-20 px-10">
      <header className="text-center">
        <h2 className="text-2xl font-semibold py-5">{t("contactus")}</h2>
        <p className="text-gray-500">{t("contact-title")}</p>
      </header>
      <form className="mt-8 flex flex-col gap-5" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-1 text-sm">
          <label>{t("full-name")}</label>
          <Input
            id="name"
            type="text"
            placeholder={t("full-name-ex") || ""}
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("name")}
            error={formik.touched.name ? formik.errors.name : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("contact-num")}</label>
          <Input
            type="text"
            id="phone"
            className="bg-white px-5 py-3 rounded-md font-poppins focus:outline-none"
            {...formik.getFieldProps("phone")}
            error={
              formik.touched.phone &&
              (formik.values.phone === "+966 " ||
                formik.values.phone === "+966" ||
                formik.values.phone === " " ||
                formik.values.phone === "")
                ? "Required"
                : ""
            }
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("companyEmail")}</label>
          <Input
            type="email"
            id="email"
            placeholder={t("companyEmailEx") || ""}
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("email")}
            error={formik.touched.email ? formik.errors.email : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("massage")}</label>
          <textarea
            placeholder={t("massage-title") || ""}
            className={`bg-white px-5 py-3 rounded-md focus:outline-none h-32 resize-none border-gray-300 border-[1px] text-gray-900 ${
              formik.touched.message && formik.errors.message
                ? " border border-red-500 bg-red-50 text-red-900 placeholder-red-700"
                : ""
            }`}
            id="message"
            {...formik.getFieldProps("message")}
          ></textarea>
          {formik.touched.message && (
            <span className="px-1 text-xs  text-red-500">
              {formik.touched.message ? formik.errors.message : ""}
            </span>
          )}
        </div>

        <div className="flex justify-end">
          <button
            className="text-white bg-PrimaryOrange text-sm font-medium px-10 py-3 rounded-lg w-fit"
            type="submit"
          >
            {t("send-massage")}
          </button>
        </div>
      </form>
    </div>
  );
}
