import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import upload from "../../icons/upload.svg";
import regions from "../../json/regions.json";
import cities from "../../json/cities.json";
import Swal from "sweetalert2";
import { Input } from "../input";
import { GiCancel } from "react-icons/gi";

export default function ApplyToJob() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [dots, setDots] = useState(0);
  const [cancelToken, setCancelToken] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "+966 ",
      email: "",
      state: "",
      city: "",
      jobId: Number(id),
      cv: file,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await applyToJob({
          ...values,
          state:
            i18n.language === "ar"
              ? JSON.parse(values.state).name_ar
              : JSON.parse(values.state).name_en,
        });
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleCancel = () => {
    if (cancelToken) {
      cancelToken.cancel("Request canceled by user.");
    }
  };

  const applyToJob = (data: any) => {
    if (file === "") {
      toast.warning(`${t("uploadWarn")}`);
    } else {
      axios
        .post("/applicants", { ...data, cv: file })
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${t("requestSent")}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setFile("");
        })
        .catch((err) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${t("requestError")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const uploadFile = async (e: any) => {
    if (e.target.files[0].size / 1024 / 1024 < 5) {
      const source = await axios.CancelToken.source();
      setCancelToken(source);
      const formData = new FormData();
      await formData.append("file", e.target.files[0]);
      setFile("");
      setFileLoading(true);
      await axios
        .post("/upload/single", formData, {
          cancelToken: source.token,
        })
        .then((res) => {
          setFile(res.data.uploaded.s3Path);
          setFileName(res.data.uploaded.filename);
          setFileLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFileLoading(false);
        });
      e.target.value = "";
    } else {
      e.target.value = "";
      toast.warn("File Size Must be less than 5 MB");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((dots) => (dots + 1) % 4);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="max-w-3xl bg-slate-100 rounded-lg mx-auto mt-20 md:py-10 py-5 md:px-20 px-10">
      <header className="text-center">
        <h2 className="text-2xl font-semibold py-5">{t("apply-for-job")}</h2>
        <p className="text-gray-500">{t("form-title")}</p>
      </header>
      <form className="mt-8 flex flex-col gap-5" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-1 text-sm">
          <label>{t("full-name")}</label>
          <Input
            type="text"
            id="name"
            placeholder={t("full-name-ex") || ""}
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("name")}
            error={formik.touched.name ? formik.errors.name : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("contact-num")}</label>
          <Input
            type="text"
            id="phone"
            className="bg-white px-5 py-3 rounded-md font-poppins focus:outline-none"
            {...formik.getFieldProps("phone")}
            error={
              formik.touched.phone &&
              (formik.values.phone === "+966 " ||
                formik.values.phone === "+966" ||
                formik.values.phone === " " ||
                formik.values.phone === "")
                ? "Required"
                : ""
            }
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("companyEmail")}</label>
          <Input
            type="email"
            id="email"
            placeholder={t("companyEmailEx") || ""}
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("email")}
            error={formik.touched.email ? formik.errors.email : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("state")}</label>
          <select
            placeholder=""
            className={`bg-white text-gray-400 px-5 py-3 rounded-md focus:outline-none ${
              formik.touched.state
                ? formik.errors.state
                  ? "border border-red-500 bg-red-50 text-red-900 placeholder-red-700"
                  : "border-gray-300 bg-gray-50 text-gray-900"
                : ""
            }`}
            id="state"
            {...formik.getFieldProps("state")}
          >
            <>
              <option disabled value="">
                {t("stateEx")}
              </option>
              {regions.map((region: any) => {
                return (
                  <option key={region.region_id} value={JSON.stringify(region)}>
                    {i18n.language === "ar" ? region.name_ar : region.name_en}
                  </option>
                );
              })}
            </>
          </select>
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("city")}</label>
          <select
            placeholder=""
            className={`bg-white text-gray-400 px-5 py-3 rounded-md focus:outline-none ${
              formik.touched.city
                ? formik.errors.city
                  ? "border border-red-500 bg-red-50 text-red-900 placeholder-red-700"
                  : "border-gray-300 bg-gray-50 text-gray-900"
                : ""
            }`}
            id="city"
            {...formik.getFieldProps("city")}
          >
            <>
              <option disabled value="">
                {t("cityEx")}
              </option>
              {formik.values.state &&
                cities
                  .filter(
                    (city: any) =>
                      city.region_id ==
                      JSON.parse(formik.values.state).region_id
                  )
                  .map((city: any) => {
                    return (
                      <option
                        key={city.city_id}
                        value={
                          i18n.language === "ar" ? city.name_ar : city.name_en
                        }
                      >
                        {i18n.language === "ar" ? city.name_ar : city.name_en}
                      </option>
                    );
                  })}
            </>
          </select>
        </div>

        <hr className="border-gray-200" />

        <div className="flex flex-col gap-1 text-sm">
          <label className="py-2 font-semibold">
            {t("upload")}
            <span className="text-red-500 px-1">*</span>
          </label>
          <div className="flex justify-end gap-4 relative cursor-pointer">
            <div className="w-full h-full z-10 py-2 mt-[-10px]">
              <input
                type="file"
                id="file"
                name="file"
                accept=".doc, .docx, .pdf"
                className="w-full h-full z-10 py-2"
                onChange={uploadFile}
              />
              <p className="text-gray-600 text-sm font-semibold">
                Supported Files : Word, PDF | Max Size 5 MB
              </p>
            </div>
            {file !== "" ? (
              <p className="text-green-500 text-base absolute ltr:right-20 rtl:left-20 top-[6px]">
                {t("uploaded")}
              </p>
            ) : fileLoading ? (
              <p
                dir="ltr"
                className="text-yellow-500 text-base absolute ltr:right-20 rtl:left-20 top-[6px]"
              >
                {t("loading")} {". ".repeat(dots)}
              </p>
            ) : (
              <p className="text-red-500 text-base absolute ltr:right-20 rtl:left-20 top-[6px]">
                * {t("select-file")}
              </p>
            )}
            <p className="w-48 text-sm text-gray-600 bg-slate-100 font-semibold absolute ltr:left-[88px] z-10 rtl:right-[88px] top-[8px]">
              <span>{fileName !== "" ? fileName : ""}</span>
              <span>
                {fileLoading ? (
                  <button
                    className="text-red-500 text-xl"
                    onClick={() => handleCancel()}
                  >
                    <GiCancel />
                  </button>
                ) : (
                  ""
                )}
              </span>
            </p>
            <img
              src={upload}
              className="w-10 absolute ltr:right-4 rtl:left-4 top-0"
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            className="text-white bg-PrimaryOrange text-sm font-medium px-10 py-3 rounded-lg w-fit"
            type="submit"
          >
            {t("send-request")}
          </button>
        </div>
      </form>
    </div>
  );
}
