import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import DisclosureBase from "../components/Disclosure";
import help2 from "../icons/help2.svg";

export default function Faqs() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto px-10">
      <h1 className="flex items-center gap-1 font-medium py-4 mt-4">
        <Breadcrumb />
      </h1>

      {/* <DisclosureBase /> */}
      <div className="flex justify-between items-center flex-col-reverse gap-5 md:flex-row bg-SecondaryGray rounded-lg px-10 py-4 max-w-xl mx-auto mt-10">
        <div className="max-w-lg flex flex-col gap-4">
          <h1 className="font-semibold text-lg font-poppins">
            {t("helpTitle2")}
          </h1>
          <p className="text-sm text-PrimaryGray">{t("helpdes2")}</p>
          <Link
            to="/contact"
            className="text-white bg-PrimaryOrange text-sm font-medium px-10 py-3 rounded-lg w-fit"
          >
            {t("helpbtn2")}
          </Link>
        </div>
        <div>
          <img src={help2} alt="help" />
        </div>
      </div>
    </div>
  );
}
