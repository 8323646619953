import { useTranslation } from "react-i18next";
import car from "../../icons/car.svg";
import radio from "../../icons/radio.svg";
import firstAidKit from "../../icons/firstAidKit.svg";
import cityCamera from "../../icons/cityCamera.svg";
import speakerLecturer from "../../icons/speakerLecturer.svg";
import policeman from "../../icons/policeman.svg";

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto py-[150px] responsive-padding">
      <div className="flex gap-4 flex-col items-center">
        <h1 className="text-2xl font-semibold">{t("benefit")}</h1>
        <p className="font-medium">{t("benefitDes")}</p>
      </div>

      <div className="grid grid-cols-12 gap-y-6 py-10">
        <div className="flex flex-col items-center col-span-12  md:col-span-3">
          <img src={car} alt="icon" className="w-24" />
          <h3 className="font-bold pb-3">{t("benefitCar")}</h3>
          <p className="text-sm text-PrimaryGray px-4">{t("benefitCarDes")}</p>
        </div>

        <div className="flex flex-col items-center col-span-12  md:col-span-3">
          <img src={radio} alt="icon" className="w-24" />
          <h3 className="font-bold pb-3">{t("benefitPhone")}</h3>
          <p className="text-sm text-PrimaryGray px-4">
            {t("benefitPhoneDes")}
          </p>
        </div>

        <div className="flex flex-col items-center col-span-12  md:col-span-3">
          <img src={firstAidKit} alt="icon" className="w-24" />
          <h3 className="font-bold pb-3">{t("benefitBag")}</h3>
          <p className="text-sm text-PrimaryGray px-4">{t("benefitBagDes")}</p>
        </div>

        <div className="flex flex-col items-center col-span-12  md:col-span-3">
          <img src={cityCamera} alt="icon" className="w-24" />
          <h3 className="font-bold pb-3">{t("benefitCam")}</h3>
          <p className="text-sm text-PrimaryGray px-4">
            {t("benefitCamDes")}
            <span className="font-poppins">{t("benefitCamDesNum")}</span>
          </p>
        </div>

        <div className="flex flex-col items-center col-span-12 md:col-start-4 md:col-span-3">
          <img src={speakerLecturer} alt="icon" className="w-24" />
          <h3 className="font-bold pb-3">{t("benefitCourse")}</h3>
          <p className="text-sm text-PrimaryGray px-4">
            {t("benefitCourseDes")}
          </p>
        </div>

        <div className="flex flex-col items-center col-span-12  md:col-span-3">
          <img src={policeman} alt="icon" className="w-24" />
          <h3 className="font-bold pb-3">{t("benefitPolise")}</h3>
          <p className="text-sm text-PrimaryGray px-4">
            {t("benefitPoliseDes")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
