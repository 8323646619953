import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import i18n from "../../i18n/config";
import Map from "../Map";
import { Input } from "../input";

export default function Pricing() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const [location, setLocation] = React.useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (lang) {
      localStorage.setItem("lang", lang || "ar");
      i18n.changeLanguage(lang);
      if (lang === "ar") {
        document.body.dir = "rtl";
        document.body.lang = "ar";
      } else {
        document.body.dir = "ltr";
        document.body.lang = "en";
      }
    }
  }, [lang]);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      comapnyPhone: "+966 ",
      email: "",
      type: "",
      address: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Required"),
      comapnyPhone: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (location.lat !== "" && location.lng !== "") {
          await sendPricing({ ...values, ...location });
          resetForm();
          setLocation({
            lat: "",
            lng: "",
          });
        } else {
          toast.warning("برجاء تحديد الموقع");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const sendPricing = (data: any) => {
    axios
      .post("/pricing", data)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${t("requestSent")}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${t("requestError")}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const getLocation = (lat: string, lng: string) => {
    setLocation({ lat: lat, lng: lng });
  };

  return (
    <div className="max-w-3xl bg-slate-100 rounded-lg mx-auto md:py-10 py-5 md:px-20 px-10">
      <header className="text-center">
        <h2 className="text-2xl font-semibold py-5">{t("ourPricing")}</h2>
        <p className="text-gray-500">{t("ourPricingDes")}</p>
      </header>

      <form className="mt-8 flex flex-col gap-5" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-1 text-sm">
          <label>{t("companyName")}</label>
          <Input
            type="text"
            id="companyName"
            placeholder={t("companyNameEx") || ""}
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("companyName")}
            error={formik.touched.companyName ? formik.errors.companyName : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("companyNumber")}</label>
          <Input
            type="text"
            id="comapnyPhone"
            className="bg-white px-5 py-3 rounded-md font-poppins focus:outline-none"
            {...formik.getFieldProps("comapnyPhone")}
            error={
              formik.touched.comapnyPhone &&
              (formik.values.comapnyPhone === "+966 " ||
                formik.values.comapnyPhone === "+966" ||
                formik.values.comapnyPhone === " " ||
                formik.values.comapnyPhone === "")
                ? "Required"
                : ""
            }
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("companyEmail")}</label>
          <Input
            type="email"
            placeholder={t("companyEmailEx") || ""}
            id="email"
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("email")}
            error={formik.touched.email ? formik.errors.email : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("address")}</label>
          <Input
            type="text"
            placeholder={t("addressEx") || ""}
            id="address"
            className="bg-white px-5 py-3 rounded-md focus:outline-none"
            {...formik.getFieldProps("address")}
            error={formik.touched.address ? formik.errors.address : ""}
          />
        </div>

        <div className="flex flex-col gap-1 text-sm">
          <label>{t("locationType")}</label>
          <select
            placeholder=""
            className={`bg-white text-gray-400 px-5 py-3 rounded-md focus:outline-none ${
              formik.touched.type
                ? formik.errors.type
                  ? "border border-red-500 bg-red-50 text-red-900 placeholder-red-700"
                  : "border-gray-300 bg-gray-50 text-gray-900"
                : ""
            }`}
            id="type"
            {...formik.getFieldProps("type")}
          >
            <option disabled value="">
              {t("locationTypeEx")}
            </option>
            <option value="تجاري">تجاري</option>
            <option value="سكني">سكني</option>
            <option value="حكومي">حكومي</option>
            <option value="مدرسة حكومية">مدرسة حكومية</option>
            <option value="مدرسة أهلية">مدرسة أهلية</option>
            <option value="ايفنت">ايفنت</option>
          </select>
          {
            <span className="px-1 text-xs  text-red-500">
              {formik.touched.type ? formik.errors.type : ""}
            </span>
          }
        </div>

        <div>
          <Map getLocation={getLocation} />
        </div>

        <div className="w-full md:w-fit flex md:justify-end">
          <button
            className="w-full md:w-fit text-white bg-PrimaryOrange text-sm font-medium px-10 py-3 rounded-lg"
            type="submit"
          >
            {t("pricingBtn")}
          </button>
        </div>
      </form>
    </div>
  );
}
