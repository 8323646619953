import { useTranslation } from "react-i18next";
import aboutBg from "../../icons/aboutBg.png";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto responsive-padding pt-12 pb-10 md:py-[200px] z-[-1] relative">
      <img src={aboutBg} alt="about" className="absolute w-[90%]" />
      <h1 className="text-2xl font-semibold pb-4">{t("aboutTitle")}</h1>
      <p className=" font-medium leading-8">
        <span className="text-PrimaryGray">{t("aboutDescription1")}</span>
        <span className="text-PrimaryGray font-poppins">
          {t("aboutDescription2")}
        </span>
        <span className="text-PrimaryGray">{t("aboutDescription3")}</span>
        <span className="text-PrimaryGray font-poppins">
          {t("aboutDescription4")}
        </span>
        <span className="text-PrimaryGray">{t("aboutDescription5")}</span>
      </p>
    </div>
  );
};

export default About;
