import React from "react";
import Pricing from "../components/forms/PricingForm";
import Benefits from "../components/service/Benefits";
import OurServices from "../components/service/Services";

export default function Services() {
  return (
    <div>
      <OurServices />
      <Benefits />
      <div className="mb-[200px]">
        <Pricing />
      </div>
    </div>
  );
}
