import Pricing from "../components/forms/PricingForm";

const ServicesApp = () => {
  return (
    <div>
      <Pricing />
    </div>
  );
};

export default ServicesApp;
