import { useTranslation } from "react-i18next";
import { HiMenu } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import useDropdown from "../hooks/useDropdown";
import Twitter from "../icons/Twitter.svg";
import aave from "../icons/aave.svg";
import googlePlay from "../icons/google-play.svg";
import RayatLogo from "../icons/logo.png";
import sms from "../icons/sms.svg";
import whatsapp from "../icons/whatsapp.svg";
import ChangeLanguage from "./ChangeLanguage";

const Navbar = () => {
  const { t } = useTranslation();
  const { ref, dorpdowm, setDorpdowm } = useDropdown();

  return (
    <nav>
      {/* first nav */}
      <div className="bg-PrimaryBlue w-full px-10">
        <div className="flex md:justify-between items-center gap-3 flex-wrap justify-center container mx-auto py-2">
          <div className="flex items-center gap-2">
            <img src={sms} alt="E-mail" />
            <div className="bg-white w-[1px] h-5"></div>
            <a
              href={`mailto:${t("email")}`}
              className="text-white text-sm font-poppins"
            >
              {t("email")}
            </a>
          </div>
          <div className="flex items-center gap-3 flex-wrap">
            {/* <a href="http://" target="_blank" rel="noreferrer">
              <img src={snapchat} alt="Snap Chat" />
            </a> */}
            <a
              href="https://twitter.com/rayatsolutions"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="Twitter" width="18" height="18" />
            </a>
            <a
              href="https://wa.me/966564914949"
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatsapp} alt="whatsapp" style={{ width: "25px" }} />
            </a>
            <div className="bg-white w-[1px] h-5"></div>
            <a
              href="https://play.google.com/store/apps/details?id=com.impulsescorp.rayat_solutions"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googlePlay} alt="Google Play" />
            </a>
            <a
              href="https://apps.apple.com/us/app/rayat-solutions/id6446064909"
              target="_blank"
              rel="noreferrer"
            >
              <img src={aave} alt="Aave" />
            </a>
          </div>
        </div>
      </div>

      {/* second nav */}
      <div className="w-full px-10">
        <div className="w-full flex justify-between items-center container mx-auto py-3">
          <div
            ref={ref}
            className="w-full flex justify-between rtl:items-end ltr:items-start lg:rtl:items-center  lg:ltr:items-center flex-col lg:flex-row relative"
          >
            <div className="w-full lg:w-fit flex flex-row justify-between items-center rtl:flex-row-reverse">
              <div className="w-56 h-full flex justify-center items-center">
                <img
                  src={RayatLogo}
                  alt="Rayat solution"
                  className="w-full h-full"
                />
              </div>
              <span
                className="cursor-pointer rounded-full"
                onClick={() => setDorpdowm(!dorpdowm)}
              >
                <HiMenu
                  className={`${
                    dorpdowm ? "text-PrimaryOrange" : "text-PrimaryBlue"
                  } lg:hidden block text-4xl font-bold p-1`}
                />
              </span>
            </div>

            {dorpdowm && (
              <div className="w-full flex lg:hidden flex-col lg:flex-row items-center gap-10 px-4 py-4">
                <ul className="text-PrimaryGray font-semibold flex items-center flex-col lg:flex-row flex-wrap gap-6 m-auto">
                  <li>
                    <NavLink
                      to={"/"}
                      onClick={() => setDorpdowm(false)}
                      className="nav-link"
                    >
                      {t("home")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/services"}
                      onClick={() => setDorpdowm(false)}
                      className="nav-link"
                    >
                      {t("services")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/careers"}
                      onClick={() => setDorpdowm(false)}
                      className="nav-link"
                    >
                      {t("careers")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/about"}
                      onClick={() => setDorpdowm(false)}
                      className="nav-link"
                    >
                      {t("about")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/contact"}
                      onClick={() => setDorpdowm(false)}
                      className="nav-link"
                    >
                      {t("contactus")}
                    </NavLink>
                  </li>
                  <li onClick={() => setDorpdowm(false)}>
                    <ChangeLanguage pos="nav" />
                  </li>
                </ul>

                <Link
                  to="/services"
                  onClick={() => setDorpdowm(false)}
                  className="bg-PrimaryOrange text-white px-6 py-2 rounded-lg font-semibold"
                >
                  {t("exploreBtn")}
                </Link>
              </div>
            )}

            <ul className="hidden lg:flex text-PrimaryGray font-semibold items-center flex-col lg:flex-row flex-wrap gap-6 m-auto">
              <li>
                <NavLink to={"/"} className="nav-link">
                  {t("home")}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/services"} className="nav-link">
                  {t("services")}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/careers"} className="nav-link">
                  {t("careers")}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/about"} className="nav-link">
                  {t("about")}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/contact"} className="nav-link">
                  {t("contactus")}
                </NavLink>
              </li>
              <li>
                <ChangeLanguage pos="nav" />
              </li>
            </ul>

            <Link
              to="/services"
              className="hidden lg:block bg-PrimaryOrange text-white px-6 py-2 rounded-lg font-semibold"
            >
              {t("exploreBtn")}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
