import * as React from "react";

const Map = ({ getLocation }) => {
  // Create a reference to the HTML element we want to put the map on
  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState(null);

  async function addDraggableMarker(map, behavior) {
    const H = window.H;
    var marker = new H.map.Marker({ lat: 24.774265, lng: 46.738586 });
    // Ensure that the marker can receive drag events
    marker.draggable = true;
    await map.addObject(marker);

    await map.addEventListener("tap", async function (evt) {
      var newCoords = map.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY
      );
      await map.removeObject(marker);
      marker = await new H.map.Marker(newCoords, {
        volatility: true,
      });
      marker.draggable = true;
      await map.addObject(marker);
      getLocation(newCoords.lat.toString(), newCoords.lng.toString());
    });

    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    await map.addEventListener(
      "dragstart",
      function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          var targetPosition = map.geoToScreen(target.getGeometry());
          target["offset"] = new H.math.Point(
            pointer.viewportX - targetPosition.x,
            pointer.viewportY - targetPosition.y
          );
          behavior.disable();
        }
      },
      false
    );
    // re-enable the default draggability of the underlying map, when dragging has completed
    await map.addEventListener(
      "dragend",
      async function (ev) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          getLocation(target?.a?.lat?.toString(), target?.a?.lng?.toString());
          behavior.enable();
        }
      },
      false
    );
    // Listen to the drag event and move the position of the marker as necessary
    await map.addEventListener(
      "drag",
      function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setGeometry(
            map.screenToGeo(
              pointer.viewportX - target["offset"].x,
              pointer.viewportY - target["offset"].y
            )
          );
        }
      },
      false
    );
  }

  React.useEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: "aedeocLrYb3xQB9zjXaY8NHRHHUwCMMzqmoYguO5zlk",
    });
    const defaultLayers = platform.createDefaultLayers();
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 24.774265, lng: 46.738586 },
      zoom: 12,
      pixelRatio: window.devicePixelRatio || 1,
    });

    window.addEventListener("resize", () => hMap.getViewPort().resize());

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

    const ui = H.ui.UI.createDefault(hMap, defaultLayers);

    addDraggableMarker(hMap, behavior);
    setMap(hMap);

    // Trigger a small resize event after the map is initialized
    setTimeout(() => {
      hMap.getViewPort().resize();
    }, 500); // Delay can be adjusted if needed

    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      hMap.dispose();
    };
  }, []); // This will run this hook every time this ref is updated

  return <div className="map w-full h-[300px] rounded" ref={mapRef} />;
};

export default Map;
